import React from 'react';
import moment from 'moment';
import User from '@components/User';
import './style.css';

function DateAndUser(props){

    return(
        <div className='d-flex gap-1'>
            {props.date? <><div className='date'>
                { moment(props.date).format('Do MMM, YYYY') }
            </div>
            <div className='date'>{moment(props.date).format('HH:mm')}</div></>
            : "_"}
            {/* <div className="image-container">
                <img src={props.image} className="image" />
                <span className="username">{props.imageText}</span>
            </div> */}
            {(props.imageText || props.image )&& 
            <User imageText={props.imageText} image={props.image} size={'25px'}/>}
        </div>
    )
}


export default DateAndUser;