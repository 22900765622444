import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import ProfileView from "./view";
import moment from "moment";

import { decryptText } from "@library/enc-dec";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserServices from "@services/Users";
import * as UserActions from "@redux/actions/Users";
import { produce } from "immer";
import { defaultBssDetails } from "./data";
import Swal from "sweetalert2";
import { getEventElementPosition } from "@helpers/common.js";
import * as UndergraduateDegreeServices from "@services/UndergraduateDegree";
import * as QualificationServices from "@services/Qualifications";
import * as FolderServices from "@services/Folders";
import { checkLoginAndRole } from "@helpers/Login/";

var roleCode, role;

function Profile(props) {
  let navigate = useNavigate();
  const countries = require("@assets/countryCode.json");
  const [allStates, setAllStates] = useState([]);
  let { id } = useParams();

  let authToken = decryptText(localStorage.getItem("aEmediat"));

  const [saveFor, setSaveFor] = useState("e");
  const [password, setPassword] = useState("");
  const [fileInputField, setFileInputFiled] = useState("");
  const [isBSSField, setIsBSSField] = useState(false);
  const [previewDocument, setPreviewDocument] = useState("");

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [allUGDegrees, setAllUGDegrees] = useState([]);
  const [allQualifications, setAllQualifications] = useState([]);
  const [folders, setFolders] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());

  useEffect(() => {
    if (id !== undefined) {
      props.resetUsersReducer();
      getUserDetails(id);
    } else {
      props.resetUsersReducer();
      setPageLoaded(true);
    }
    [role, roleCode] = checkLoginAndRole("users");
    getAllUGDegrees();
    getAllQualifications();
    getAllFolders();
  }, []);

  const getAllFolders = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: "0",
        limit: "10000",
        isSorted: true,
        sortBy: "title",
        searchText: "",
        visibility: "active",
      };

      const response = await FolderServices.getAllFolders(payload, authToken);
      var allFolders = response.data
        .map((folder) => (folder.pageType === "user" ? folder : null))
        .filter(Boolean);

      if (roleCode === 2 || roleCode === "2") {
        if (props.access?.userRights) {
          allFolders = allFolders.filter((folder) =>
            props.access.userRights.includes(folder._id)
          );
        } else {
          allFolders = [];
        }
      }
      setFolders(allFolders);
    } catch (err) {
      console.log("Error coming while fetching all folders", err);
    }
  };

  const getAllUGDegrees = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 99999,
        isSorted: false,
        sortBy: "createdAt",
        searchText: "",
        visibility: "active",
      };

      var response = await UndergraduateDegreeServices.getAllUGDegrees(
        payload,
        authToken
      );

      if (response.success) {
        setAllUGDegrees(response.data);
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching all ug degrees", err);
    }
  };

  const getAllQualifications = async () => {
    try {
      const payload = {
        pageNo: 0,
        limit: 10000,
        visibility: "active",
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await QualificationServices.getAllQualifications(
        payload,
        authToken
      );

      if (response.success) {
        const qualifications = response.data
          .map((qualification) => qualification.qualifications)
          .flat();
        setAllQualifications(qualifications);
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching all qualifications", err);
    }
  };

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      const {
        username,
        displayName,
        email,
        countryCode,
        contactNumber,
        whatsAppCountryCode,
        whatsAppNumber,
        dob,
        notes,
      } = draft;
      draft.isPersonalInfoCompleted = false;
      if (
        username &&
        displayName &&
        email &&
        countryCode &&
        contactNumber &&
        whatsAppCountryCode &&
        whatsAppNumber &&
        dob 
      ) {
        draft.isPersonalInfoCompleted = true;
      }
    });
    props.updateUserDetails(updatedUserDetails);
  }, [
    props.userDetails.username,
    props.userDetails.displayName,
    props.userDetails.email,
    props.userDetails.countryCode,
    props.userDetails.contactNumber,
    props.userDetails.whatsAppCountryCode,
    props.userDetails.whatsAppNumber,
    props.userDetails.dob,
    props.userDetails.notes,
  ]);

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      const { firstName, lastName } = draft;
      draft.isLegalInfoCompleted = false;
      if (firstName && lastName) {
        draft.isLegalInfoCompleted = true;
      }
    });
    props.updateUserDetails(updatedUserDetails);
  }, [
    props.userDetails.firstName,
    props.userDetails.middleName,
    props.userDetails.lastName,
  ]);

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      const {
        flatHouseNumber,
        buildingName,
        colonyName,
        areaName,
        landmark,
        country,
        pincode,
        city,
        state,
      } = draft;
      draft.isAddressInfoCompleted = false;
      if (
        flatHouseNumber &&
        buildingName &&
        colonyName &&
        areaName &&
        landmark &&
        country &&
        pincode &&
        city &&
        state
      ) {
        draft.isAddressInfoCompleted = true;
      }
    });
    props.updateUserDetails(updatedUserDetails);
  }, [
    props.userDetails.flatHouseNumber,
    props.userDetails.buildingName,
    props.userDetails.colonyName,
    props.userDetails.areaName,
    props.userDetails.landmark,
    props.userDetails.country,
    props.userDetails.pincode,
    props.userDetails.city,
    props.userDetails.state,
  ]);

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      const {
        isUnderGraduate,
        underGraduateDegree,
        qualification,
        marksheetOrDegreeCert,
        fullName,
        addharOrIdProof,
        passportPhoto,
        identityAcknowledged,
      } = draft;
      draft.isIdentityInfoCompleted = false;
      if (
        ((underGraduateDegree || qualification)) &&
        marksheetOrDegreeCert&&
        fullName &&
        addharOrIdProof &&
        passportPhoto &&
        identityAcknowledged
      ) {
        draft.isIdentityInfoCompleted = true;
      }
    });
    props.updateUserDetails(updatedUserDetails);
  }, [
    props.userDetails.isUnderGraduate,
    props.userDetails.underGraduateDegree,
    props.userDetails.qualification,
    props.userDetails.marksheetOrDegreeCert,
    props.userDetails.fullName,
    props.userDetails.addharOrIdProof,
    props.userDetails.passportPhoto,
    props.userDetails.identityAcknowledged,
  ]);

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      const {
        guardianName,
        bloodGroup,
        underGraduateDegree,
        underGraduateInstituteName,
        underGraduateInstituteAddress,
        registrationNumber,
        passingYear,
        aadhaarCard,
        panCard,
        otherDocs,
        signature,
        placeOfSignature,
        dateOfSignature,
        bssAcknowledged,
      } = draft?.bssDetails[0];

      if (draft?.bssDetails.length > 0 && draft?.bssDetails[0]) {
        draft.bssDetails[0].isBssInfoCompleted = false;

        if (
          guardianName &&
          bloodGroup &&
          underGraduateDegree &&
          underGraduateInstituteName &&
          underGraduateInstituteAddress &&
          registrationNumber &&
          passingYear &&
          aadhaarCard &&
          panCard &&
          otherDocs &&
          signature &&
          placeOfSignature &&
          dateOfSignature &&
          bssAcknowledged
        ) {
          draft.bssDetails[0].isBssInfoCompleted = true;
          draft.bssDetails[0].bssVerificationStatus = 'unVerified'
        }
      }
    });
    props.updateUserDetails(updatedUserDetails);
  }, [
    props.userDetails.bssDetails[0]?.guardianName,
    props.userDetails.bssDetails[0]?.bloodGroup,
    props.userDetails.bssDetails[0]?.underGraduateDegree,
    props.userDetails.bssDetails[0]?.underGraduateInstituteName,
    props.userDetails.bssDetails[0]?.underGraduateInstituteAddress,
    props.userDetails.bssDetails[0]?.registrationNumber,
    props.userDetails.bssDetails[0]?.passingYear,
    props.userDetails.bssDetails[0]?.aadhaarCard,
    props.userDetails.bssDetails[0]?.panCard,
    props.userDetails.bssDetails[0]?.otherDocs,
    props.userDetails.bssDetails[0]?.signature,
    props.userDetails.bssDetails[0]?.placeOfSignature,
    props.userDetails.bssDetails[0]?.dateOfSignature,
    props.userDetails.bssDetails[0]?.bssAcknowledged,
  ]);

  useMemo(() => {
    if (!pageLoaded) {
      return;
    }
    let country = countries.filter(
      (country) => country.countryName === props.userDetails.country
    );
    if (country.length > 0) {
      setAllStates(country[0].regions?.map((region) => region.name));
    }
  }, [props.userDetails.country]);

  const getUserDetails = async (userId) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserServices.getUserById(userId, authToken);
      if (response.success) {
        props.updateUserDetails(response.data);
        setPageLoaded(true);
        return;
      }
      setPageLoaded(true);
      alert(response.message);
    } catch (err) {
      console.log("Error coming while fetching  user details", err);
    }
  };

  const handleChange = (value, field) => {
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      if (field === "country") {
        draft.state = "";
      } else if (field === "dob") {
        draft.dob = moment(value).toISOString();
      } else if (field === "contactNumber") {
        draft.isContactNumberVerified = false;
      } else if (field === "email") {
        draft.isEmailVerified = false;
      } else if (field === "isEmailVerified") {
        draft.isEmailVerified = value === "true" ? true : false;
        return;
      } else if (field === "countryCode") {
        let country = countries.filter(
          (country) => country.countryName === value
        );
        draft.country = value;
        draft.countryCode = country[0].countryCode;
        return;
      } else if (field === "whatsAppCountryCode") {
        let country = countries.filter(
          (country) => country.countryName === value
        );
        draft.whatsAppCountry = value;
        draft.whatsAppCountryCode = country[0].countryCode;
        return;
      } else if (field === "isUnderGraduate") {
        draft.isUnderGraduate = value === "true" ? true : false;
        return;
      }
      draft[field] = value;
    });
    props.updateUserDetails(updatedUserDetails);
  };

  const handleBSSChange = (value, field) => {
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      if (draft.bssDetails.length === 0) {
        draft.bssDetails = defaultBssDetails;
      } else if (field === "dateOfSignature") {
        value = moment(value).toISOString();
      }
      if (value === "Rejected") {
        if (field === "guardianNameStatus") {
          draft.bssDetails[0]["guardianName"] = "";
        } else if (field === "underGraduateDegreeStatus") {
          draft.bssDetails[0]["underGraduateDegree"] = "";
        } else if (field === "underGraduateInstituteNameStatus") {
          draft.bssDetails[0]["underGraduateInstituteName"] = "";
        } else if (field === "underGraduateInstituteAddressStatus") {
          draft.bssDetails[0]["underGraduateInstituteAddress"] = "";
        } else if (field === "registrationNumberStatus") {
          draft.bssDetails[0]["registrationNumber"] = "";
        } else if (field === "passingYearStatus") {
          draft.bssDetails[0]["passingYear"] = "";
        } else if (field === "signatureVerificationStatus") {
          draft.bssDetails[0]["signature"] = "";
        } else if (field === "aadhaarVerificationStatus") {
          draft.bssDetails[0]["aadhaarCard"] = "";
        } else if (field === "panVerificationStatus") {
          draft.bssDetails[0]["panCard"] = "";
        } else if (field === "otherDocsVerificationStatus") {
          draft.bssDetails[0]["otherDocs"] = "";
        } else if (field === "placeOfSignatureStatus") {
          draft.bssDetails[0]["placeOfSignature"] = "";
        }
      }

      if(field==='placeOfSignature'){
        draft.bssDetails[0].placeOfSignatureStatus = 'unVerified'
      }
      draft.bssDetails[0][field] = value;
      draft.bssDetails[0]['bssVerificationStatus'] = 'unVerified'
    });
    props.updateUserDetails(updatedUserDetails);
  };

  const handleFileUpload = (fileInputId, field, bssField = false) => {
    try {
      document.getElementById(fileInputId).click();
      setFileInputFiled(field);
      setIsBSSField(bssField);
    } catch (err) {
      console.log("Error coming while uploading file", err);
    }
  };

  const convertToBase64 = async (event) => {
    try{
    const file = event.target.files[0];
    if (file) {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { fieldName: fileInputField, thumbnailUrl: file };
      let response = await UserServices.uploadUserDocuments(payload, authToken);
      if (response.success) {
        setInputKey(Date.now());
        let updatedUserDetails = produce(props.userDetails, (draft) => {
          if (isBSSField) {
            draft["bssDetails"][0][fileInputField] = response.data;
            if(fileInputField === 'signature'){
              draft.bssDetails[0].placeOfSignatureStatus = 'unVerified'
              draft.bssDetails[0].bssVerificationStatus = 'unVerified'
              
            }
          } else {
            draft[fileInputField] = response.data;
          }
        });
        props.updateUserDetails(updatedUserDetails);
      } else {
        alert("File too large! Please reduce the file size and try again.");
      }
    }}
    catch(e){
      console.log("FIle Error:", e);
    }
  };

  const handleDeleteProfilePicture = (event) => {
    let updatedUserDetails = produce(props.userDetails, (draft) => {
      draft.displayPhoto = null;
    });

    props.updateUserDetails(updatedUserDetails);
  };

  const handleSave = async () => {
    try {
      setIsFormSaving(true);
      const { userDetails } = props;

      const payload = {
        userId: id ? id : null, // null or any valid user id
        userDetails: {
          username: props.userDetails.username,
          displayName: props.userDetails.displayName,
          displayPhoto: props.userDetails.displayPhoto,
          email: props.userDetails.email,
          dob: props.userDetails.dob,
          notes: props.userDetails.notes,
          countryCode: props.userDetails.countryCode,
          contactNumber: props.userDetails.contactNumber,
          whatsAppCountryCode: props.userDetails.whatsAppCountryCode,
          whatsAppCountry: props.userDetails.whatsAppCountry,
          whatsAppNumber: props.userDetails.whatsAppNumber,
          isEmailVerified: props.userDetails.isEmailVerified,
          isContactNumberVerified: props.userDetails.isContactNumberVerified,
          isPersonalInfoCompleted: props.userDetails.isPersonalInfoCompleted,
          firstName: props.userDetails.firstName,
          middleName: props.userDetails.middleName,
          lastName: props.userDetails.lastName,
          isLegalInfoCompleted: props.userDetails.isLegalInfoCompleted,
          flatHouseNumber: props.userDetails.flatHouseNumber,
          buildingName: props.userDetails.buildingName,
          colonyName: props.userDetails.colonyName,
          areaName: props.userDetails.areaName,
          landmark: props.userDetails.landmark,
          country: props.userDetails.country,
          state: props.userDetails.state,
          city: props.userDetails.city,
          pincode: props.userDetails.pincode,
          addressMode: props.userDetails.addressMode,
          isAddressInfoCompleted: props.userDetails.isAddressInfoCompleted,
          isUnderGraduate: props.userDetails.isUnderGraduate,
          underGraduateDegree: props.userDetails.isUnderGraduate ? props.userDetails.underGraduateDegree : null,
          qualification: props.userDetails.isUnderGraduate? null : props.userDetails.qualification,
          marksheetOrDegreeCert: props.userDetails.marksheetOrDegreeCert,
          fullName: props.userDetails.fullName,
          addharOrIdProof: props.userDetails.addharOrIdProof,
          passportPhoto: props.userDetails.passportPhoto,
          identityAcknowledged: props.userDetails.identityAcknowledged,
          isIdentityInfoCompleted: props.userDetails.isIdentityInfoCompleted,
          isOTPRequired: props.userDetails.isOTPRequired,
          isBasicDetailsRequired: props.userDetails.isBasicDetailsRequired,
          isIdentityVerificationRequired:
            props.userDetails.isIdentityVerificationRequired,
          isLegalInfoRequired: props.userDetails.isLegalInfoRequired,
          isDeliveryAddressRequired:
            props.userDetails.isDeliveryAddressRequired,
          isBSSDetailsRequired: props.userDetails.isBSSDetailsRequired,
        },
        bssDetails: {
          guardianName: props.userDetails.bssDetails[0]?.guardianName,
          bloodGroup: props.userDetails.bssDetails[0]?.bloodGroup,
          underGraduateDegree:
            props.userDetails.bssDetails[0]?.underGraduateDegree,
          underGraduateInstituteName:
            props.userDetails.bssDetails[0]?.underGraduateInstituteName,
          underGraduateInstituteAddress:
            props.userDetails.bssDetails[0]?.underGraduateInstituteAddress,
          registrationNumber:
            props.userDetails.bssDetails[0]?.registrationNumber,
          passingYear: props.userDetails.bssDetails[0]?.passingYear,
          aadhaarCard: props.userDetails.bssDetails[0]?.aadhaarCard,
          panCard: props.userDetails.bssDetails[0]?.panCard,
          otherDocs: props.userDetails.bssDetails[0]?.otherDocs,
          signature: props.userDetails.bssDetails[0]?.signature,
          placeOfSignature: props.userDetails.bssDetails[0]?.placeOfSignature,
          dateOfSignature: props.userDetails.bssDetails[0]?.dateOfSignature,
          bssAcknowledged: props.userDetails.bssDetails[0]?.bssAcknowledged,
          guardianNameStatus:
            props.userDetails.bssDetails[0]?.guardianNameStatus,
          underGraduateDegreeStatus:
            props.userDetails.bssDetails[0]?.underGraduateDegreeStatus,
          underGraduateInstituteNameStatus:
            props.userDetails.bssDetails[0]?.underGraduateInstituteNameStatus,
          underGraduateInstituteAddressStatus:
            props.userDetails.bssDetails[0]
              ?.underGraduateInstituteAddressStatus,
          registrationNumberStatus:
            props.userDetails.bssDetails[0]?.registrationNumberStatus,
          passingYearStatus: props.userDetails.bssDetails[0]?.passingYearStatus,
          signatureVerificationStatus:
            props.userDetails.bssDetails[0]?.signatureVerificationStatus,
          aadhaarVerificationStatus:
            props.userDetails.bssDetails[0]?.aadhaarVerificationStatus,
          panVerificationStatus:
            props.userDetails.bssDetails[0]?.panVerificationStatus,
          otherDocsVerificationStatus:
            props.userDetails.bssDetails[0]?.otherDocsVerificationStatus,
          placeOfSignatureStatus:
            props.userDetails.bssDetails[0]?.placeOfSignatureStatus,
          bssVerificationStatus:
            props.userDetails.bssDetails[0]?.bssVerificationStatus,
          isBssInfoCompleted:
            props.userDetails.bssDetails[0]?.isBssInfoCompleted,
        },
      };

      const isValidPersonalInfo = validatePersonalInfo(payload);

      let requiredFields = [
        "username",
        "displayName",
        "email",
        "contactNumber",
        "countryCode",
      ];
      for (let item in payload) {
        if (requiredFields.includes(item) && !payload[item]) {
          alert(`${item.toUpperCase()} Required!`);
          setIsFormSaving(false);
          return;
        }
      }

      if (isValidPersonalInfo.success) {
        const response = await UserServices.createAndUpdateUser(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: "User created/updated",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }

        setIsFormSaving(false);
        return;
      }

      setIsFormSaving(false);
      alert(isValidPersonalInfo.message);
    } catch (err) {
      console.log("Error coming while creating user", err);
      setIsFormSaving(false);
    }
  };

  const validatePersonalInfo = (paylaod) => {
    const {
      email,
      contactNumber,
      whatsAppNumber,
      dob,
      whatsAppCountryCode,
      countryCode,
    } = paylaod.userDetails;

    if (!email) {
      return { success: false, message: "Invalid email" };
    }

    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      return { success: false, message: "Invalid email" };
    }

    var contactNumberRegex = /^\d{10}$/;

    let isValidContactNumber = contactNumberRegex.test(contactNumber);

    if (
      (!isValidContactNumber && contactNumber.length < 10) ||
      !countryCode ||
      countryCode === "null"
    ) {
      return {
        success: false,
        message: "Invalid Contact Number /  Country code missing",
      };
    }

    // if (!whatsAppNumber) {
    // 	return {
    // 		success: false,
    // 		message: 'Invalid Whatsapp Number',
    // 	};
    // }

    // let isValidWNumber = contactNumberRegex.test(whatsAppNumber);

    // if (
    // 	(!isValidWNumber && whatsAppNumber.length < 10) ||
    // 	!whatsAppCountryCode ||
    // 	whatsAppCountryCode === 'null'
    // ) {
    // 	return {
    // 		success: false,
    // 		message: 'Invalid Whatsapp Number / Country code missing',
    // 	};
    // }
    // if (dob) {
    // 	let year = dob.split('-')[0];
    // 	let minYear = new Date().getFullYear() - 80;
    // 	let currentYear = new Date().getFullYear() - 16;
    // 	if (
    // 		year.length === 4 &&
    // 		Number(year) > minYear &&
    // 		Number(year) < currentYear
    // 	) {
    // 		return { success: true, message: 'Valid Date of Birth' };
    // 	}

    // 	return { success: false, message: 'Invalid Date of Birth' };
    // }

    return { success: true, message: "Valid payload" };
  };

  const handleUpdateEmailStatus = (event) => {
    let val = event.target.value;
    if (val === "verify") {
      let updatedUserDetails = produce(props.userDetails, (draft) => {
        draft.isEmailVerified = true;
      });
      props.updateUserDetails(updatedUserDetails);
    } else if (val === "unverify") {
      let updatedUserDetails = produce(props.userDetails, (draft) => {
        draft.isEmailVerified = false;
      });
      props.updateUserDetails(updatedUserDetails);
    }
  };

  const showDocuments = (event, field, isBSSField) => {
    event.stopPropagation();
    if (isBSSField) {
      var document =
        props.userDetails?.bssDetails.length > 0
          ? props.userDetails?.bssDetails[0][field]
          : props.userDetails?.bssDetails[field];
    } else {
      var document = props.userDetails[field];
    }
    setPreviewDocument(document);
    handlePopUpOpen(event, "docView");
  };

  //START : Popup fns
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };

  const updatePassword = async () => {
    if (id) {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        userId: id,
        password: password,
      };
      let response = await UserServices.updateUserPassword(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: "Password updated!",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Create user before updating password",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  const moveToFolder = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        foldersIdList: data,
        usersList: id ? [id] : [],
        currentFolderId: props.tab._id == "All" ? null : props.tab._id,
        pageType: "user",
      };
      const response = await FolderServices.moveToFolders(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: `Moved Successfully`,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };

  const handlePopUpSave = async (data, event) => {
    try {
      handlePopUpClose();
      if (popUp === "moveTo") {
        moveToFolder(data);
      }
    } catch (err) {
      console.log("Error coming while saving popup data", err);
    }
  };

  return (
    <>
      <ProfileView
        userDetails={props.userDetails}
        allStates={allStates}
        password={password}
        handlePassword={(e) => setPassword(e.target.value)}
        updatePassword={updatePassword}
        handleChange={handleChange}
        handleBSSChange={handleBSSChange}
        // Handling displaying uploaded documents
        showDocument={showDocuments}
        //START : UseStates & fn to control the functionality of upating verification popup
        saveFor={saveFor}
        handleFileUpload={handleFileUpload}
        convertToBase64={convertToBase64}
        handleDeleteProfilePicture={handleDeleteProfilePicture}
        handleSave={handleSave}
        handleUpdateEmailStatus={handleUpdateEmailStatus}
        previewDocument={previewDocument}
        popUp={popUp}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        isFormSaving={isFormSaving}
        id={id}
        allUGDegrees={allUGDegrees}
        allQualifications={allQualifications}
        folders={folders}
        handlePopUpSave={handlePopUpSave}
        roleCode={roleCode}
        inputKey={inputKey}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.UserTab,
    userDetails: state.user.userDetails,
    access: state.login.access,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserDetails: UserActions.updateUserDetails,
      resetUsersReducer: UserActions.resetUsersReducer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
