import React, { useEffect, useState, useRef } from "react";
import DetailsView from "./view";
import { useParams, useNavigate } from "react-router-dom";

import { getEventElementPosition } from "@helpers/common.js";
import * as SeriesServices from "@services/Series";
import { decryptText } from "@library/enc-dec";
import Swal from "sweetalert2";
import { navigationData } from "./data";

function Details() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [seriesDetails, setSeriesDetails] = useState([]);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    getSeriesDetails();
  }, []);

  const getSeriesDetails = async () => {
    try {
      if (id) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await SeriesServices.getSeriesById(id, authToken);
        if (response.success) {
          setSeriesDetails(response.data);
          return;
        }
      }
      // New Course will add
      // setCourseDetails(newEmptySeries);
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    setPopUp("");
  };
  const handlePopUpSave = async () => {
    handlePopUpClose();
  };

  const handleChange = async (value, field) => {
    var updatedSeriesDetails = { ...seriesDetails };
    if (field == "thumbnailUrl") {
      value = value[0];
      handlePopUpClose();
    }
    updatedSeriesDetails[field] = value;

    setSeriesDetails(updatedSeriesDetails);
  };

  const handleSave = async () => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      if (
        !seriesDetails.title ||
        !seriesDetails.internalTitle ||
        !seriesDetails.mnemonic ||
        !seriesDetails.validityDays
      ) {
        Swal.fire({
          icon: "error",
          title: "Fields are empty",
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
      let payload = {
        seriesId: id ? id : null,
        title: seriesDetails.title,
        internalTitle: seriesDetails.internalTitle,
        mnemonic: seriesDetails.mnemonic,
        thumbnailUrl: seriesDetails.thumbnailUrl,
        validityDays: seriesDetails.validityDays,
      };

      const response = await SeriesServices.updateSeriesDetails(
        payload,
        authToken
      );
      if (response.success) {
        setIsFormSaving(false);

        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });

        navigate(`/series/${response.data._id.toString()}`, {
          state: { finalNavigationData: navigationData },
        });

        return;
      }
      setIsFormSaving(false);
      Swal.fire({
        icon: "error",
        title: response.message ? response.message: 'File too large! Please reduce the file size and try again.',
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } catch (err) {}
  };

  return (
    <DetailsView
      seriesDetails={seriesDetails}
      handleChange={handleChange}
      handleSave={handleSave}
      popUp={popUp}
      popUpPosition={popUpPosition}
      isPopUpVisible={isPopUpVisible}
      handlePopUpClose={handlePopUpClose}
      handlePopUpOpen={handlePopUpOpen}
      handlePopUpSave={handlePopUpSave}
      isFormSaving={isFormSaving}
    />
  );
}

export default Details;
