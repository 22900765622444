import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import VideoDetailsView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { decryptText } from '@library/enc-dec';

import * as VideosServices from '@services/Videos';
import * as VideosActions from '@redux/actions/Videos';

import Swal from 'sweetalert2';
import { produce } from 'immer';

function VideoDetails(props) {
	const navigate = useNavigate();
	const { id } = useParams();
	let authToken = decryptText(localStorage.getItem('aEmediat'));

	const additionalInfoRef = useRef(null);
	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	const [additionalInfoCheck, setAdditionalInfoCheck] = useState(true);
	const [thumbnailObj, setThumbnailObj] = useState(null);
	const [isFormSaving, setIsFormSaving] = useState(false)

	useEffect(() => {
		getVideoDetails();
	}, []);

	const getVideoDetails = async () => {
		try {
			if (id !== undefined) {
				const videoDocId = id;
				const response = await VideosServices.getVideo(videoDocId, authToken);
				if (response.success) {
					props.updateVideoDetails(response.data);
					return;
				}

				alert(response.message);
			} else {
				props.resetVideosReducer();
			}
		} catch (err) {
			console.log('Error coming while fetching video details');
		}
	};

	const handleTitle = (event) => {
		setFormValuesEmpty(false);
		props.updateVideoTitle(event.target.value);
	};

	const handleDescription = (event) => {
		setFormValuesEmpty(false);
		props.updateVideoDescription(event.target.value);
	};

	const handleThumbnailUrl = (thumbnailObj) => {
		setFormValuesEmpty(false);
		setThumbnailObj(thumbnailObj.thumbnail);
	};

	const handleVideoLink = (event) => {
		setFormValuesEmpty(false);
		props.updateVideoLink(event.target.value);
	};

	const handleHideLikes = (event) => {
		props.updateHideLikes(event.target.checked);
	};

	const handleHideDislikes = (event) => {
		props.updateHideDislikes(event.target.checked);
	};

	const handleCommentsStatus = (event, value) => {
		if (value === 'heldForReview') {
			props.updateCommentsStatus('heldForReview');
			return;
		} else if (value === 'disabled') {
			props.updateCommentsStatus('disabled');
			return;
		}
		props.updateCommentsStatus('enabled');
	};

	const handleAdditionalInfoCheck = (event) => {
		setAdditionalInfoCheck(event.target.checked);
		if (event.target.checked) {
			props.updateAdditionalInfo('');
			return;
		}
		props.updateAdditionalInfo(null);
	};

	const handleAdditionalInfo = (content) => {
		if (additionalInfoCheck) {
			const { additionalInfo } = props;
			const editorInstance = additionalInfoRef.current.getEditor();
			const text = editorInstance.getText().trim();
			if (text.length !== 0) {
				const updatedIndfo = produce(additionalInfo, (draft) => {
					draft.pointer = content;
				});
				props.updateAdditionalInfo(updatedIndfo);
			} else {
				const updatedIndfo = produce(additionalInfo, (draft) => {
					draft.pointer = '';
				});
				props.updateAdditionalInfo(updatedIndfo);
			}
		}
	};

	//START : fn to handle selection of bullet icon
	const handleBulletIconChange = (bulletIcon) => {
		const { additionalInfo } = props;
		let updatedInfo
		if(typeof additionalInfo ==='object')
		{
		 updatedInfo = produce(additionalInfo, (draft) => {

			draft.icon = bulletIcon.icon;
		});
		}
		else{
			updatedInfo={
				icon:bulletIcon.icon}
		}

		props.updateAdditionalInfo(updatedInfo);
	};
	//END : fn to handle selection of bullet icon

	const handleVisibility = (event, value) => {
		if (value === 'purchasedRequired') {
			props.updateIsPublic(false);
		} else {
			props.updateIsPublic(true);
		}
	};

	const handleSave = async () => {
		try {
			setIsFormSaving(true)
			const { title, description, videoLink, thumbnailUrl, isPublic } = props;
			const { commentsStatus, hideDislikes, hideLikes, additionalInfo } = props;

			const payload = {
				videoDocId: id,
				title: title,
				description: description,
				additionalInfo: additionalInfo,
				videoLink: videoLink,
				thumbnailUrl: thumbnailObj ? thumbnailObj : thumbnailUrl,
				hideDislikes: hideDislikes,
				hideLikes: hideLikes,
				commentsStatus: commentsStatus,
				isPublic: isPublic,
			};

			for (let item in payload) {
				if (payload[item] === '' || payload[item] === null) {
					setFormValuesEmpty(true);
					Swal.fire('Field Missing!', item + ' is required!', 'warning');			
					setIsFormSaving(false)
					return;
				}
			}

			setFormValuesEmpty(false);

			if (id !== undefined) {
				const response = await VideosServices.updateVideo(payload, authToken);
				if (response.success) {	
							
					setIsFormSaving(false)
					props.updateVideoDetails(response.data);
					Swal.fire({
						icon: "success",
						title: response.message,
						showConfirmButton: false,
						timer: 1500
					  });
					return;
				}
				Swal.fire({
					icon: "error",
					title: response.message ? response.message: 'File too large! Please reduce the file size and try again.',
					showConfirmButton: false,
					timer: 1500
				  });
				setIsFormSaving(false)
			} else {
				const response = await VideosServices.addVideo(payload, authToken);
				if (response.success) {		
					setIsFormSaving(false)
					props.updateVideoDetails(response.data);
					Swal.fire({
						icon: "success",
						title: response.message,
						showConfirmButton: false,
						timer: 1500
					  });
					navigate('/content');
					return;
				}
				Swal.fire({
					icon: "error",
					title: response.message ? response.message: 'File too large! Please reduce the file size and try again.',
					showConfirmButton: false,
					timer: 1500
				  });
				setIsFormSaving(false)
			}
		} catch (err) {
			setIsFormSaving(false)
			console.log('Error coming while updating the video details', err);
		}
	};

	return (
		<>
			<VideoDetailsView
				videoTitle={props.title}
				videoDescription={props.description}
				additionalInfoRef={additionalInfoRef}
				additionalInfoCheck={additionalInfoCheck}
				additionalInfo={props.additionalInfo}
				videoLink={props.videoLink}
				thumbnailUrl={props.thumbnailUrl}
				hideLikes={props.hideLikes}
				hideDislikes={props.hideDislikes}
				holdCommentsForReview={props.commentsStatus === 'heldForReview'}
				disableComments={props.commentsStatus === 'disabled'}
				information={props.additionalInfo}
				visibility={props.isPublic}
				isFormSaving={isFormSaving}
				handleTitle={handleTitle}
				handleDescription={handleDescription}
				handleAdditionalInfo={handleAdditionalInfo}
				handleThumbnailUrl={handleThumbnailUrl}
				handleVideoLink={handleVideoLink}
				handleHideLikes={handleHideLikes}
				handleHideDislikes={handleHideDislikes}
				handleCommentsStatus={handleCommentsStatus}
				handleVisibility={handleVisibility}
				handleAdditionalInfoCheck={handleAdditionalInfoCheck}
				formValuesEmpty={formValuesEmpty}
				handleSave={handleSave}
				// START : Usestate & fn for RichEditor
				//START : Usestate & fn for bullet icon
				handleBulletIconChange={handleBulletIconChange}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		title: state.videos.title,
		description: state.videos.description,
		videoLink: state.videos.videoLink,
		thumbnailUrl: state.videos.thumbnailUrl,
		additionalInfo: state.videos.additionalInfo,
		hideLikes: state.videos.hideLikes,
		hideDislikes: state.videos.hideDislikes,
		commentsStatus: state.videos.commentsStatus,
		isPublic: state.videos.isPublic,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateVideoDetails: VideosActions.updateVideoDetails,
			updateVideoTitle: VideosActions.updateVideoTitle,
			updateVideoDescription: VideosActions.updateVideoDescription,
			updateVideoLink: VideosActions.updateVideoLink,
			updateThumbnailUrl: VideosActions.updateThumbnailUrl,
			updateAdditionalInfo: VideosActions.updateAdditionalInfo,
			updateHideLikes: VideosActions.updateHideLikes,
			updateHideDislikes: VideosActions.updateHideDislikes,
			updateCommentsStatus: VideosActions.updateCommentsStatus,
			updateIsPublic: VideosActions.updateIsPublic,
			resetVideosReducer: VideosActions.resetVideosReducer,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);
